/******************
 * Helpers 
 * Funciones para ayudar a las operaciones
 * by: Ezequiel Cohort
 */
import Countries from "./countries.json";

export const getEdad = (cumple) => {
    const today = new Date();
    const dob = new Date(cumple);
    const edad = parseInt((today - dob)/365/24/60/60/1000);

    return edad;
}


export const getPais = (countryISO2) => {
    const pais = Countries.filter( country => country.code.includes(countryISO2));
    if (pais[0].name) return pais[0].name;
    else return false;
}

export const nroFactura = (punto, nro_factura) => {
    const zeroStr = "00000000";
    let pto = zeroStr + punto,
        fac = zeroStr + nro_factura;
    const factura = pto.slice(-4) + "-" + fac.slice(-8);

    return factura;
}

export const formatFecha = (fecha) => {
    const fec = new Date(fecha);
    let newFec = fec.getDate() + "/" + (fec.getMonth()+1).toString() + "/" + fec.getUTCFullYear();
    return newFec;
}

export const formatFechaHora = (fecha) => {
    const fec = new Date(fecha);
    let newFec = fec.getDate() + "/" + (fec.getMonth()+1).toString() + "/" + fec.getUTCFullYear() + " " + fec.getUTCHours() + ":" + fec.getUTCMinutes();
    return newFec;
}