/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col
} from "reactstrap";
import Toast from 'react-bootstrap/Toast'
import {register, checkEmail} from "../../network/ApiAxios";
import config from "../../config";

const Register = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [checkbox, setCheckbox] = useState(true);
    const [error, setError] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Email enviado! Revísalo para activar tu cuenta.");
    const [userID, setUserID] = useState(null);
    const [emailVerificado, setEmailVerificado] = useState(false);
    const [registrado, setRegistrado] = useState(false);

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const verifyEmail = async () => {
        setError("");
        if (!email || !pattern.test (email)) {
            setError("Debe completar el campo de email correctamente!");
            return;
        }
        const userResponse = await checkEmail(email);
        if (userResponse.data.user === null) {
            setError("Este email NO está registrado en nuesta Base de Datos!");
            return;
        }

        const userData = userResponse.data.user;
        setUserID(userData._id);
        setEmailVerificado(true);

    }

    const registerUser = async () => {
        if (!(name && email && password && confirmPassword && checkbox)) {
            setError("Debe completar todos los campos!");
            return;
        }

        if (password !== confirmPassword) {
            setError("Las contraseñas no coinciden!");
            return;
        }
        const response = await register(name, email, password, userID);
        const {data} = response;
        if (!data.success) {
            setError(data.msg);
            return;
        }
        if (config.DEMO) {
            setToastMessage("This is a demo, so we will not send you an email. Instead, click on this link to verify your account:")
            setUserID(data.userID);
        }
        setError("");
        setName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setCheckbox(true);
        setShowToast(true);
        setEmailVerificado(false);
        setUserID(null);
        setRegistrado(true);

    };

    return (
        <>
            <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: 'fixed',
                    minHeight: '100px',
                    width: "35%",
                    right: 10,
                    bottom: 80,
                    zIndex: 50
                }}
            >
                <Toast style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 10
                }} onClose={() => setShowToast(false)} show={showToast} delay={10000} autohide={!config.DEMO}>
                    <Toast.Header>
                        <img style={{height: "30px", width: "100px"}} src={require("assets/img/brand/aa-logo-header.png").default}  alt="..."/>
                    </Toast.Header>
                    <Toast.Body>
                        {toastMessage}
                        {config.DEMO ?
                            <a href={config.DOMAIN_NAME + '/auth/confirm-email/' + userID}>
                                {config.DOMAIN_NAME + '/auth/confirm-email/' + userID}
                            </a> : null}
                    </Toast.Body>
                </Toast>
            </div>
            <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                    {!registrado?
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Active su cuenta con el e-mail declarado en AADIDESS</small>
                        </div>
                        <Form role="form">
                            <FormGroup>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Email" type="email" autoComplete="new-email" value={email}
                                           onChange={e => setEmail(e.target.value)} readOnly={emailVerificado}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {!emailVerificado?
                            <div className="text-center">
                                <Button onClick={verifyEmail} className="mb-4" color="success" type="button">
                                    Verificar Email
                                </Button>
                            </div>
                            :null}
                            {emailVerificado?
                            <span>
                            <FormGroup>
                                <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-hat-3"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Nick" type="text" value={name}
                                           onChange={e => setName(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Password" type="password" autoComplete="new-password" value={password}
                                           onChange={e => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Repìta Password" type="password" autoComplete="new-password" value={confirmPassword}
                                           onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            </span>
                            : null }
                            {error ?
                            <div className="text-muted font-italic">
                                <small>
                                    Error:{" "}
                                    <span className="text-red font-weight-700">{error}</span>
                                </small>
                            </div> : null }
                            {/*******
                            <Row className="my-4">
                                <Col xs="12">
                                    <div className="custom-control custom-control-alternative custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="customCheckRegister"
                                            type="checkbox"
                                            checked={checkbox}
                                            onChange={() => setCheckbox(!checkbox)}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="customCheckRegister"
                                        >
                                        <span className="text-muted">
                                        I agree with the{" "}
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Privacy Policy
                                        </a>
                                        </span>
                        
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            **************/}
                            {emailVerificado?
                            <div className="text-center">
                                <Button onClick={registerUser} className="mt-4" color="primary" type="button">
                                    Habilitar Cuenta
                                </Button>
                            </div>
                            :null}
                        </Form>
                    </CardBody>
                    :
                    <CardBody className="px-lg-5 py-lg-5">
                        <h3>Felicitaciones, se ha registrado correctamente!!</h3>
                        <p>Estará recibiendo un email para confirmar su cuenta.</p>
                        <p>Muchas gracias!</p>
                    </CardBody>
                    }
                </Card>
            </Col>
        </>
    );
};

export default Register;
