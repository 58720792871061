import React, {useEffect, useState} from 'react';

import { UncontrolledAlert } from 'reactstrap';
import { formatFechaHora } from "helpers/common";
import { getMessages, putMessagesVisto } from 'network/ApiAxios';

let user = JSON.parse(localStorage.getItem("user"));
let userInfo = {};
if (user)  userInfo = user.info;

const Mensajes = (props) => {
    let mensajes = {};
    const [msgsG, setMsgsG] = useState([]);
    const [msgsP, setMsgsP] = useState([]);

    useEffect(() => {
        const runAsync = async () => {
            user = await JSON.parse(localStorage.getItem("user"));
            userInfo = user.info;
            mensajes = await getMensajes();
            if (mensajes) {
                if (mensajes.gral.length > 0) setMsgsG(mensajes.gral);
                if (mensajes.msgs.length > 0) setMsgsP(mensajes.msgs);
            }
        }
        runAsync();
        //let intMsgs = setInterval(getMensajes,60000);
    }, []);

    const getMensajes = async () => {
        if (userInfo._id) {
            mensajes = await getMessages(userInfo._id);
            if (mensajes.data.success) {
                mensajes = mensajes.data;
                if (mensajes.gral.length > 0) setMsgsG(mensajes.gral);
                if (mensajes.msgs.length > 0) setMsgsP(mensajes.msgs);
            }

            return mensajes;
        }
    }

    return (
        <>
            {msgsG.length === 0 && msgsP.length === 0 ? <SinMensaje key={1} />:
            <div>
                {msgsG.map((msg, index) => 
                    <MessageLine key={`G-${index}`} mensaje={msg} />
                    )}
                {msgsP.map((msg, index) => 
                    <MessageLine key={`P-${index}`} mensaje={msg} />
                    )}
            </div>
            }
        </>
    );
}

export default Mensajes;

function MessageLine({mensaje}) {
    const [visible, setVisible] = useState(true);

    const onDismiss = () => {
        setVisible(false);
        putMessagesVisto(mensaje._id);
    }
    let color = "info";
    if (mensaje.tipo === 'G') color='warning';
    return (
        <UncontrolledAlert color={color} isOpen={visible} toggle={onDismiss}>
            <h4 className="alert-heading">Mensaje de {mensaje.from_name} - {formatFechaHora(mensaje.created_at)}</h4>
            <div>
            {mensaje.message}
            </div>
        </UncontrolledAlert>
    );
}

function SinMensaje() {
    let color = "success";
    return (
        <UncontrolledAlert color={color}>
            <h3 className="success-heading">No tienes mensajes</h3>
        </UncontrolledAlert>
    );
}