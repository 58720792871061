/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Alert,
    CardTitle,
    CardImg
} from "reactstrap";

import { Link } from "react-router-dom";

import Header from "components/Headers/Header.js";
import Facturas from "./profile/Facturas";
import Titulos from "./profile/Titulos";
import Cuotas from "./profile/Cuotas";
import Mensajes from "./profile/Mensajes";


import icnPerfil from "assets/img/icons/aad-icono-perfil.png";
import icnFactur from "assets/img/icons/aad-icono-facturas.png";
import icnCuotas from "assets/img/icons/aad-icono-cuotas.png";
import icnTitulo from "assets/img/icons/aad-icono-titulo.png";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            userInfo: JSON.parse(localStorage.getItem("user")).info,
            btnState: 0,
        };
    }

    componentDidMount() {
        if (this.state.userInfo) {
            this.setState({
              user_id: this.state.userInfo._id,
            });
        }
    }

    chgBtnState = (btn) => {
        this.setState({
            btnState: btn
        });
    };

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className="mt-3" fluid>
                    <Button onClick={e => this.chgBtnState(5)}>Hola {this.state.user.name} - Ver mensajes</Button>
                </Container>
                <Container className="mt-5">
                    <Alert style={{color: '#0c5460', backgroundColor: '#d1ecf1', borderColor: '#bee5eb', fontSize: '0.93rem'}}>
                        Por favor, mantenga sus datos de <Link to="/admin/user-profile" >perfil</Link> actualizados!
                    </Alert>
                    <Row className="mb-3">
                        <Card className="text-center col-6 col-sm-3">
                            <Link to="/admin/user-profile">
                            <CardImg top src={icnPerfil} className="img-fluid" alt="Perfil" title="Perfil"  />
                            <CardTitle tag="h2">Perfil</CardTitle>
                            </Link>
                        </Card>
                        <Card className="text-center col-6 col-sm-3">
                            <CardImg top src={icnFactur}  className="img-fluid" onClick={e => this.chgBtnState(2)} alt="Facturas" title="Facturas" />
                            <CardTitle tag="h2" onClick={e => this.chgBtnState(2)} >Facturas</CardTitle>
                        </Card>
                        <Card className="text-center col-6 col-sm-3">
                            <CardImg src={icnCuotas}  className="img-fluid" onClick={e => this.chgBtnState(3)} alt="Cuotas" title="Cuotas" />
                            <CardTitle tag="h2" onClick={e => this.chgBtnState(3)} >Cuotas</CardTitle>
                        </Card>
                        <Card className="text-center col-6 col-sm-3">
                            <img src={icnTitulo}  className="img-fluid" onClick={e => this.chgBtnState(4)} alt="Titulos" title="Titulos" />
                            <CardTitle tag="h2" onClick={e => this.chgBtnState(4)} >Titulos</CardTitle>
                        </Card>
                    </Row>
                </Container>
                <Container className="mt-4" fluid>
                    {/*<h1>{this.state.btnState}</h1>*/}
                    {this.state.btnState===1? <h2>Boton Perfil </h2>:null}
                    {this.state.btnState===2? <Facturas />:null}
                    {this.state.btnState===3? <Cuotas />:null}
                    {this.state.btnState===4? <Titulos />:null}
                    {this.state.btnState===5? <Mensajes />:null}
                </Container>
            </>
        );
    }
}

export default Index;