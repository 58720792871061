/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import EditHeader from "../../components/Headers/EditHeader";
import {edit, getDelegaciones} from "../../network/ApiAxios";
import Countries from "helpers/countries.json";

const EditProfile = props => {

    let user = JSON.parse(localStorage.getItem("user"));
    let userInfo = user.info;
    let dele = "";
    if (userInfo.delegacion) dele = userInfo.delegacion._id;

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);

    const [delegacion, setDelegacion] = useState(dele);
    const [centro_ski, setCentro_ski] = useState(userInfo.centro_ski);
    const [apellido, setApellido] = useState(userInfo.apellido);
    const [nombre, setNombre] = useState(userInfo.nombre);
    const [tipo_doc, setTipo_doc] = useState(userInfo.tipo_doc);
    const [documento, setDocumento] = useState(userInfo.documento);
    const [estado_civil, setEstado_civil] = useState(userInfo.estado_civil);
    const [telefono, setTelefono] = useState(userInfo.telefono);
    const [movil, setMovil] = useState(userInfo.movil);

    //* Address */
    const [calle, setCalle] = useState(userInfo.direccion.calle);
    const [localidad, setLocalidad] = useState(userInfo.direccion.localidad);
    const [provincia, setProvincia] = useState(userInfo.direccion.provincia);
    const [cod_postal, setCod_postal] = useState(userInfo.direccion.cod_postal);
    const [pais, setPais] = useState(userInfo.direccion.pais);

    //const paisesOpts = getOptionsPais();

    const [isTestUser, setIsTestUser] = useState(false);
    const [Paises, setPaises] = useState([]);
    const [Delegaciones, setDelegaciones] = useState([]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")).email === "test@test.com") {
            setIsTestUser(true);
        }
        setPaises(Countries);

        getDeles();
    }, []);

    const getDeles = async () => {
        const deles = await getDelegaciones();
        setDelegaciones(deles.data.delegaciones);
    }

    const editUser = async () => {
        let direccion = {
            calle,
            localidad,
            provincia,
            cod_postal,
            pais,
        },
        info = {
            ...userInfo,
            deleID: delegacion,
            apellido,
            nombre,
            tipo_doc,
            documento,
            estado_civil,
            telefono,
            movil,
            direccion,
            centro_ski
        };
        
        const response = await edit(user._id, name, email, info);
        const { data } = response;
        if (data.success) {
            user = {...user, name, email, info}
            localStorage.setItem("user", JSON.stringify(user));
            props.history.push("/admin/user-profile");
        }
    }

    return (
        <>
            <EditHeader/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Mis datos</h3>
                                        {isTestUser ? <h5>You are not allowed to edit the test user. Create another user to test this functionality</h5> : null}
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="primary"
                                            href="#"
                                            onClick={editUser}
                                            size="sm"
                                            disabled={isTestUser}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Información de usuario
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Nick
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={name}
                                                        id="input-username"
                                                        placeholder="Username"
                                                        onChange={e => setName(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        E-mail
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                        type="email"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4"/>
                                    {/* Socio */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Información de Socio
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-delegacion"
                                                    >
                                                        Delegación:
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={delegacion}
                                                        id="input-delegacion"
                                                        placeholder="Delegacion"
                                                        onChange={e => setDelegacion(e.target.value)}
                                                        type="select"
                                                    >
                                                        {Delegaciones.map(dele => (
                                                            <option key={dele._id} value={dele._id}>{dele.name}</option>
                                                        )
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Centro de Esquí que trabaja
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-centro-sky"
                                                        value={centro_ski}
                                                        onChange={e => setCentro_ski(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Apellido/s
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={apellido}
                                                        id="input-apellido"
                                                        placeholder="Apellido"
                                                        onChange={e => setApellido(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Nombre/s
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={nombre}
                                                        id="input-nombre"
                                                        placeholder="Nombre"
                                                        onChange={e => setNombre(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="1">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Tipo
                                                    </label>
                                                    <Input 
                                                        type="select" 
                                                        name="select" 
                                                        id="select-tipo-doc" 
                                                        className="" 
                                                        defaultValue={tipo_doc}
                                                        onChange={e=> setTipo_doc(e.target.value)}
                                                    >
                                                        <option value="">Tipo Documento</option>
                                                        <option value="DNI">DNI</option>
                                                        <option value="PASAPORTE">PASAPORTE</option>
                                                        <option value="CUIT">CUIT</option>
                                                        <option value="CUIL">CUIL</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                            <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Documento
                                                    </label>
                                                <FormGroup>
                                                    <Input
                                                        className="form-control-alternative md-9"
                                                        defaultValue={documento}
                                                        id="input-documento"
                                                        placeholder="Documento"
                                                        onChange={e => setDocumento(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Estado Civil
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={estado_civil}
                                                        id="input-estado-civil"
                                                        placeholder="Estado Civil"
                                                        onChange={e => setEstado_civil(e.target.value)}
                                                        type="select"
                                                    >
                                                        <option value="">Seleccione</option>
                                                        <option value="SOLTERO">SOLTERO</option>
                                                        <option value="CASADO">CASADO</option>
                                                        <option value="SEPARADO">SEPARADO</option>
                                                        <option value="DIVORCIADO">DIVORCIADO</option>
                                                        <option value="VIUDO">VIUDO</option>
                                                        <option value="OTRO">OTRO</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Teléfono
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={telefono}
                                                        id="input-telefono"
                                                        placeholder="Teléfono"
                                                        onChange={e => setTelefono(e.target.value)}
                                                        type="number"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Móvil
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={movil}
                                                        id="input-movil"
                                                        placeholder="Movil"
                                                        onChange={e => setMovil(e.target.value)}
                                                        type="number"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4"/>
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Dirección
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Calle
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={calle}
                                                        id="input-calle"
                                                        placeholder="Dirección"
                                                        onChange={e => setCalle(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Localidad
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={localidad}
                                                        id="input-localidad"
                                                        placeholder="Localidad"
                                                        onChange={e => setLocalidad(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Provincia
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={provincia}
                                                        id="input-provincia"
                                                        placeholder="Provincia"
                                                        onChange={e => setProvincia(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Pais
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        value={pais}
                                                        id="input-country"
                                                        placeholder="Pais"
                                                        onChange={e => setPais(e.target.value)}
                                                        type="select"
                                                    >
                                                        {Paises.map(pais => (
                                                            <option key={pais.code} value={pais.code}>{pais.name}</option>
                                                        )
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Código Postal
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={cod_postal}
                                                        id="input-postal-code"
                                                        placeholder="Código Postal"
                                                        onChange={e => setCod_postal(e.target.value)}
                                                        type="number"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
);
}

export default EditProfile;
