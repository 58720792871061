import React from 'react';

// core components
import Header from "components/Headers/Header.js";
import Facturas from './Facturas';

const ListFacturas = (props) => {
    return (
        <>
            <Header />
            {/* Page content */}
            <Facturas />
        </>
    );
};

export default ListFacturas;