import React, {useState, useEffect} from 'react';

// import 'assets/css/facturas.css';

import { 
    Container,
    Row,
    Col,
    Table,
    Card,
    CardFooter,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import { useParams } from 'react-router-dom';

import { getFactura } from "../../network/ApiAxios";
import { nroFactura, formatFecha, getPais } from "../../helpers/common";
import Header from "components/Headers/Header";
import logoFactura from "../../assets/img/brand/header-factura-600.jpg";


const Factura = () => {
    const {id} = useParams();

    const [factura, setFactura] = useState({});
    const [items, setItems] = useState([]);
    //Datos Factura
    const [nro_factura, setNroFactura] = useState("");
    const [nombre, setNombre] = useState("");
    const [direccion, setDireccion] = useState({});
    const [documento, setDocumento] = useState("");
    const [tipo_doc, setTipoDoc] = useState("");
    const [telefono, setTelefono] = useState("");
    const [forma_pago, setFPago] = useState("");
    const [detalleFP, setDetFPago] = useState("");

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        const runAsync = async () => {
            const response = await getFactura(id);
            const {data} = response;
            if (data.success) {
                setFactura(data.factura);
                setItems(data.items);
                setNroFactura(nroFactura(data.factura.punto,data.factura.nro_factura));
                setNombre(data.factura.apellido + ", " + data.factura.nombre);
                let dir = {
                    calle: data.factura.direccion.calle,
                    localidad: data.factura.direccion.localidad,
                    provincia: data.factura.direccion.provincia,
                    pais: getPais(data.factura.direccion.pais),
                    cod_postal: data.factura.direccion.cod_postal
                }
                setDireccion(dir);
                setDocumento(data.factura.CUIT);
                setTipoDoc(data.factura.persona.tipo_doc);
                setTelefono(data.factura.persona.telefono);
                setFPago(data.factura.forma_pago.nombre);

                let dpg = '';
                switch(parseInt(data.factura.forma_pago.campos)) {
                  case 1:
                      dpg = 'Cheque #: ' + data.factura.detalle_pago.cheque + '\n';
                      dpg += 'Banco: ' + data.factura.detalle_pago.banco + '\n';
                      break;
                  case 2:
                      dpg = 'Transferencia #: ' + data.factura.detalle_pago.transferencia + '\n';
                      dpg += 'Banco: ' + data.factura.detalle_pago.banco + '\n';
                      break;
                  case 3:
                      dpg = 'Tarjeta: ' + data.factura.detalle_pago.tarjeta + '\n';
                      dpg += 'Cupón: ' + data.factura.detalle_pago.cupon + '\n';
                      break;
                  case 4:
                      dpg = 'Cupón: ' + data.factura.detalle_pago.cupon + '\n';
                      break;
                  case 5:
                      dpg = 'Detalle: ' + data.factura.detalle_pago.detalle + '\n';
                      break;
                  default:
                      dpg = '';
                }

                setDetFPago(dpg)
            }
        }
        runAsync();
    }, []);

    return (
        <>
        <Header />
        <Container className="bg-white mt-3 p-3">
            <Card className="shadow">
                <CardHeader className="bg-transparent border-0">
                    <Row>
                        <Col><img className="img-fluid" src={logoFactura} alt="" /></Col>
                        <Col>
                            <div style={{fontWeight:600, color: '#000'}} className="mt-3" >Factura</div>
                            <div style={{fontWeight:600, color: '#000'}}>{nro_factura}</div>
                            <div style={{color: '#000'}} className="mt-1">Fecha: {formatFecha(factura.fecha)}</div>
                        </Col>
                    </Row>
                    <Row className="mt-4" >
                        <Col>
                            <Row>
                                <Col>
                                    {nombre}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {direccion.calle}<br />
                                    {direccion.localidad}, {direccion.provincia}, {direccion.pais}<br />
                                    ({direccion.cod_postal})
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>{tipo_doc}: {documento}</Col>
                            </Row>
                            <Row>
                                <Col>Teléfono: {telefono}</Col>
                            </Row>
                            <Row>
                                <Col xs="auto">Forma de Pago:</Col>
                                <Col xs="auto">{forma_pago}<br />{detalleFP}</Col>
                            </Row>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="mt-4">
                        <Table className="table-flush" responsive>
                            <thead>
                                <tr className="bg-light">
                                    <th scope="col" style={{width: '50%'}} className="text-center">Servicio</th>
                                    <th scope="col" style={{width: '13%'}} className="text-right">Precio</th>
                                    <th scope="col" style={{width: '11%'}} className="text-right">Cantidad</th>
                                    <th scope="col" style={{width: '13%'}} className="text-right">Descuento</th>
                                    <th scope="col" style={{width: '13%'}} className="text-right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => <LineaItem key={index} item={item} index={index} /> )}
                            </tbody>
                        </Table>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Row className="pt-5" >
                        <Table className="font-weight-bold">
                            <tbody>
                                <tr>
                                    <td className="text-right" style={{width: '87%'}}>Total:</td>
                                    <td className="text-right" style={{width: '13%'}}>$ {factura.total}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            {/*<Button color="primary" onClick={toggle}>Imprimir / Descargar</Button>*/}
                            <a href={"https://new.aadidess.com/facturas/verfactura/"+id} className="btn btn-primary btn-lg" target="_blank" rel="noreferrer" >Imprimir / Descargar</a>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Factura: {nro_factura}
                </ModalHeader>
                <ModalBody className="embed-responsive embed-responsive-16by9" style={{height: '100%'}} >
                    <iframe title={`Factura ${nro_factura}`} className="embed-responsive-item" src={`https://new.aadidess.com/facturas/verfactura/${id}`} />
                </ModalBody>
            </Modal> 
        </Container>
        </>
    );
};

function LineaItem ({item, index}) {

    const servicio = item.category.name + " | " + item.item.descripcion,
          precio = item.precio,
          cantidad = item.cantidad,
          descuento = item.descuento,
          total = item.total;

    return (
        <tr>
            <td>{servicio}</td>
            <td className="text-right">$ {precio}</td>
            <td className="text-right">{cantidad}</td>
            <td className="text-right">$ {descuento}</td>
            <td className="text-right">$ {total}</td>
        </tr>
    );
}

export default Factura;

