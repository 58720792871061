import React from 'react';

// core components
import Header from "components/Headers/Header.js";
import Titulos from './Titulos';


const ListTitulos = (props) => {
    return (
        <>
            <Header />
            {/* Page content */}
            <Titulos />
        </>
    );
};

export default ListTitulos;