import axios from 'axios';
import config from "../config";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = (token ? token : '');
    config.headers.ContentType = 'application/json';
    return config;
});

export const getAll = async () => (
    await instance.post('users/all')
);

export const register = async (name, email, password, sysID) => (
    await instance.post('users/register', {name, email, password, sysID})
);

export const confirmRegister = async id => (
    await instance.post(`users/confirm/${id}`)
);

export const forgotPassword = async email => (
    await instance.post('users/forgotpassword', {email})
);

export const confirmReset = async (id, password) => (
    await instance.post(`users/resetpass/${id}`, {password})
);

export const login = async (email, password) => (
    await instance.post('users/login', {email, password})
);

export const logout = async token => (
    await instance.post('users/logout', {token})
);

export const edit = async (userID, name, email, info) => (
    await instance.post('/users/edit', {userID, name, email, info})
);

export const checkEmail = async email => (
    await instance.post(`users/checkEmail/${email}`)
);

export const getSocio = async userID => (
    await instance.post(`users/getSocio/${userID}`)
);

export const getDelegacion = async deleID => (
    await instance.post(`users/delegacion/${deleID}`)
);

export const getDelegaciones = async () => (
    await instance.post(`users/delegaciones`)
);

export const getCuotas = async idSocio => (
    await instance.post(`users/cuotas/${idSocio}`)
);

export const getTitulos = async idSocio => (
    await instance.post(`users/titulos/${idSocio}`)
);

export const getFacturas = async idSocio => (
    await instance.post(`users/facturas/${idSocio}`)
);

export const getFactura = async idFactura => (
    await instance.post(`users/factura/${idFactura}`)
);

export const getMessages = async idSocio => (
    await instance.post(`users/messages/${idSocio}`)
);

export const putMessagesVisto = async idMensaje => (
    await instance.post(`users/messages/${idMensaje}/visto`)
);