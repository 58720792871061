/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Navbar,
    Nav,
    Container,
    Media,
} from "reactstrap";
import {logout} from "../../network/ApiAxios";

const AdminNavbar = props => {

    const [foto, setFoto] = useState("");;
    const [username, setUsername] = useState("");

    useEffect(() => {
        if (localStorage.getItem("user")) {
            setFoto(JSON.parse(localStorage.getItem("user")).info.foto);
            setUsername(JSON.parse(localStorage.getItem("user")).name);
        } else {
            logOut();  
        }
    }, []);

    const logOut = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            const response = await logout(token);
            const { data } = response;
            if (data.success) {}
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                props.history.push("/auth/login");
        }
    }

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                        to="/"
                    >
                        {props.brandText}
                    </Link>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        {/*********
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav className="nav-link-icon">
                                <i className="ni ni-bell-55" /><sup className="badge bg-danger" >4</sup> {totMensajes}
                            </DropdownToggle>
                            <DropdownMenu
                                aria-labelledby="navbar-default_dropdown_1"
                                className="dropdown-menu-arrow"
                                right
                            >
                                <DropdownItem>Action</DropdownItem>
                                <DropdownItem>Another action</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Something else here</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        *************/}
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        {foto?
                                            <img
                                            alt="..."
                                            src={foto}
                                            />
                                        :
                                            <img
                                            alt="..."
                                            src="https://www.aadidess.com/wp-content/uploads/thumb.jpg"
                                            />
                                        }
                                    </span>
                                    <Media className="ml-2 d-none d-lg-block">
                                        <span className="mb-0 text-sm font-weight-bold">
                                            {username}
                                        </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Bienvenido!</h6>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-single-02"/>
                                    <span>Mi perfil</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/edit-profile" tag={Link}>
                                    <i className="ni ni-settings-gear-65"/>
                                    <span>Editar</span>
                                </DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem href="#logout" onClick={logOut}>
                                    <i className="ni ni-user-run"/>
                                    <span>Salir</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;