import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Card,
    CardHeader,
    Table,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import {Link } from "react-router-dom";
import {getFacturas} from "../../network/ApiAxios";
import {nroFactura, formatFecha} from "../../helpers/common";

let user = JSON.parse(localStorage.getItem("user"));

const Facturas = (props) => {
    const [facturas, setFacturas] = useState([]);
    useEffect(() => {
        const runAsync = async () => {
            user = await JSON.parse(localStorage.getItem("user"));
            const response = await getFacturas(user.id_sys);
            const {data} = response;
            if (data.success) {
                setFacturas(data.facturas);
            }
        }
        runAsync();
    }, []);
    
    return (
        <>
            <Container className="mt-3" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Facturas</h3>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Factura #</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Forma de Pago</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col" style={{width: '5%'}}>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {facturas.map((factura, index) => <Linea key={index} factura={factura} index={index} />)}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
                {/* Dark table */}
            </Container>
        </>
    );
}

export default Facturas;


function Linea({factura, index}) {
    return (
        
        <tr>
        <th scope="row">
            <Link to={`/admin/factura/${factura._id}`}>
            <span className="mb-0 text-sm" style={{color: '#525f7f'}}>
                {nroFactura(factura.punto, factura.nro_factura)}
            </span>
            </Link>
        </th>
        <td>{formatFecha(factura.fecha)}</td>
        <td>{factura.tipo_doc}</td>
        <td>
            $ {factura.total}
        </td>
        <td>
            <Badge
                color=""
                className="badge-dot mr-4"
            >
                <i className="bg-success" />
                {factura.forma_pago.nombre}
            </Badge>
        </td>
        <td>
            {factura.status}
        </td>
        <td className="text-center">
            <a href={"https://new.aadidess.com/facturas/verfactura/"+factura._id} target="_blank" rel="noreferrer" ><i className="fas fa-print mr-3" id={"print-" + index} style={{color: '#525f7f'}} ></i></a>
            <UncontrolledTooltip placement="top" target={"print-" + index}>
                Imprimir Factura
            </UncontrolledTooltip>
            <Link to={`/admin/factura/${factura._id}`} ><i className="fas fa-eye" id={"eye-" + index} style={{color: '#525f7f'}} ></i></Link>
            <UncontrolledTooltip placement="top" target={"eye-" + index}>
                Ver Factura
            </UncontrolledTooltip>
        </td>
    </tr>
    );
}