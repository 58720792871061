import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Card,
    CardHeader,
    Table,
    UncontrolledTooltip,
    CardFooter,
} from "reactstrap";
import {Link } from "react-router-dom";
import {getCuotas} from "../../network/ApiAxios";
import {nroFactura, formatFecha} from "../../helpers/common";

let user = JSON.parse(localStorage.getItem("user"));
const Cuotas = (props) => {

    const [cuotas, setCuotas] = useState([]);
    const [nrocuotas, setNroCuotas] = useState(0);

    useEffect(() => {
        const runAsync = async () => {
            user = await JSON.parse(localStorage.getItem("user"));
            const response = await getCuotas(user.id_sys);
            const {data} = response;
            if (data.success) {
                const CO = await ordenarCuotas(data.cuotas);
                setCuotas(CO);
                //setCuotas(data.cuotas);
                setNroCuotas(data.cuotas.length);
            }
        }
        runAsync();
    }, []);
    
    return (
        <>
            <Container className="mt-3" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Cuotas {nrocuotas>0? <span>- Cantidad de cuotas: {nrocuotas}</span> :null}</h3>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Factura #</th>
                                        <th scope="col" style={{width: '5%'}}>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cuotas.length === 0? null:
                                    cuotas.map((cuota, index) => <Linea key={index} cuota={cuota} indice={index} />)}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
                {/* Dark table */}
            </Container>
        </>
    );
}

export default Cuotas;


function Linea({cuota, indice}) {
    return (
        <tr>
            {cuota.importada? <th scope="row" colSpan={4}>Cuota {indice+1} - importada sistema anterior - {cuota.anio} </th>
            :
            <th scope="row">
                <span className="mb-0 text-md">
                    Cuota {indice+1}
                </span>
                <span className="text-uppercase" style={{fontSize:11}}> - ({cuota.descripcion})</span>
            </th>
            }
            {!cuota.importada?<td>{formatFecha(cuota.fecha)}</td>:null}
            {!cuota.importada?<td>{nroFactura(cuota.punto, cuota.nro_factura)}</td>:null}
            {!cuota.importada?
            <td className="text-center">
                <Link to={`/admin/factura/${cuota.id_factura}`} ><i className="fas fa-eye" id={"eye-" + indice} ></i></Link>
                <UncontrolledTooltip placement="top" target={"eye-" + indice}>
                    Ver Factura
                </UncontrolledTooltip>
            </td>:null}
        </tr>
    );
}
const ordenarCuotas = async (cuotas) => {
    let descripcion = "",
        anio = 0;
    let aCuotas = await cuotas.map(cuota => {
        if (cuota.importada) {
            let serv = cuota.servicio.split(" - ")[1].slice(0,4);
            descripcion = serv;
            anio = serv;
        } else {
            descripcion = cuota.item.descripcion;
            anio = cuota.item.anio;
        }
        return {
            descripcion,
            anio,
            id_factura: cuota.id_factura._id,
            fecha: cuota.id_factura.fecha,
            punto: cuota.id_factura.punto,
            nro_factura: cuota.id_factura.nro_factura,
            importada: cuota.importada
        }
    })

    await aCuotas.sort(function (a, b) {
        if (a.anio > b.anio) {
            return 1;
        }
        if (a.anio < b.anio) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

    return aCuotas;
}