import React, { useState, useEffect } from 'react';

import {
    Container,
    Row,
    Card,
    CardHeader,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import {getTitulos} from "../../network/ApiAxios";
import {formatFecha} from "../../helpers/common";

let user = JSON.parse(localStorage.getItem("user"));

const Titulos = (props) => {
    const {className} = props;

    const [titulos, setTitulos] = useState([]);
    const [modal, setModal] = useState(false);
    const [contenido, setContenido] = useState({});

    const toggle = () => setModal(!modal);

    const modalTitulo = (index) => {
        const icono = "https://new.aadidess.com" + titulos[index].disciplina.icono;
        const observaciones = (titulos[index].observaciones === 'COVID-19'? <span>Vencimiento modificado por COVID-19</span>:titulos[index].observaciones);
        
        setContenido({
            titulo: titulos[index].titulo.name,
            icono: icono,
            disciplina: titulos[index].disciplina.name,
            otorgado: formatFecha(titulos[index].recibido),
            vencimiento: formatFecha(titulos[index].vencimiento),
            notas: observaciones
        })
        toggle();
    }

    useEffect(() => {
        const runAsync = async () => {
            user = await JSON.parse(localStorage.getItem("user"));
            const response = await getTitulos(user.id_sys);
            const {data} = response;
            if (data.success) {
                setTitulos(data.titulos);
            }
        }
        runAsync();
    }, []);
    
    return (
        <>
            <Container className="mt-3" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Titulos</h3>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Titulo</th>
                                        <th scope="col" style={{ width: "5%" }}>
                                            Disciplina
                                        </th>
                                        <th
                                            scope="col"
                                            style={{ width: "10%" }}
                                        >
                                            Otorgado
                                        </th>
                                        <th
                                            scope="col"
                                            style={{ width: "10%" }}
                                        >
                                            Vencimiento
                                        </th>
                                        <th scope="col">Observaciones</th>
                                        <th scope="col" style={{ width: "5%" }}>
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {titulos.map((titulo, index) => (
                                        <Linea
                                            key={index}
                                            titulo={titulo}
                                            index={index}
                                            modalTitulo={modalTitulo}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4"></CardFooter>
                        </Card>
                    </div>
                </Row>
                {/* Dark table */}
            </Container>

            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>
                    <img src={contenido.icono} height="22px" alt={contenido.disciplina} title={contenido.disciplina} />  {contenido.titulo}
                </ModalHeader>
                <ModalBody>
                    <b>Otorgado:</b> {contenido.otorgado} <br />
                    <b>Vencimiento:</b> {contenido.vencimiento} <br />
                    <b>Observaciones: </b> {contenido.notas} <br />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Titulos;

function Linea({titulo, index, modalTitulo}) {

    let icono = "https://new.aadidess.com" + titulo.disciplina.icono,
        vencol = "#525f7f";
    if (titulo.observaciones === 'COVID-19') vencol = "#f5365c"
    return (
        <tr>
        <th scope="row">
            <span className="mb-0 text-sm">
                {titulo.titulo.name}
            </span>
        </th>
        <td className="text-center"><img src={icono} height="25px" alt={titulo.disciplina.name} title={titulo.disciplina.name} /></td>
        <td>{formatFecha(titulo.recibido)}</td>
        <td style={{color:vencol}}>{formatFecha(titulo.vencimiento)}</td>
        <td>
            {titulo.observaciones === 'COVID-19'? <span>Vencimiento modificado por COVID-19</span>:titulo.observaciones}
        </td>
        <td className="text-center">
            <i className="fas fa-eye" id={"eye-" + index} onClick={e => modalTitulo(index)} ></i>
            <UncontrolledTooltip placement="top" target={"eye-" + index}>
                Ver Título
            </UncontrolledTooltip>
        </td>
    </tr>
    );
}